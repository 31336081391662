



































































import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";

import { AnalyticsAppointmentData } from "@/types/interfaces";

export default Vue.extend({
    components: {
        Spinners
    },
    computed: {
        loading(): boolean {
            return analyticsStore.loading;
        },

        appointmentData(): AnalyticsAppointmentData {
            return analyticsStore.appointmentData;
        },

        confirmedPercentText(): string {
            if (this.appointmentData.confirmed < 1) {
                return "";
            }

            const percent =
                (this.appointmentData.confirmed / this.appointmentData.total) *
                100;

            return ` (${percent}%)`;
        },

        virtApptsPercentText(): string {
            if (this.appointmentData.virtApptsWithMeetingTime < 1) {
                return "";
            }

            const virtualAppointments =
                "Virtual" in this.appointmentData.locationTypes
                    ? this.appointmentData.locationTypes["Virtual"]
                    : 0;

            if (virtualAppointments < 1) {
                return "";
            }

            const percent =
                (this.appointmentData.virtApptsWithMeetingTime /
                    virtualAppointments) *
                100;

            return `(${percent}%)`;
        },

        tableHeaders(): Array<string> {
            return ["Type", "Number of Appts", "Percentage"];
        },

        flatData(): Array<Array<string>> {
            return Object.keys(this.appointmentData.locationTypes).map(
                (key) => {
                    const displayedKey = key !== "" ? key : "None Specified";
                    const apptCount = this.appointmentData.locationTypes[key];
                    const apptPercentage =
                        (apptCount / this.appointmentData.total) * 100;
                    return [
                        displayedKey,
                        `${apptCount}`,
                        `${apptPercentage.toFixed(1)}%`
                    ];
                }
            );
        },

        tableFooter(): Array<string> {
            return ["Total Appointments", `${this.appointmentData.total}`, ""];
        }
    },

    mounted() {
        this.getData();
    },

    methods: {
        getData() {
            analyticsStore.getAppointmentData();
        }
    }
});
